import React from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import Typography from '@/components/atoms/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

const Root = styled.div`
  height: 100vh;
  padding-top: ${({ theme }) => theme.spacing(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Merci = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Layout invertNav type="magasin">
      <Seo title="contact" description="merci" />
      <Root>
        <Typography
          as="h2"
          variant="h2"
          css={css`
            text-align: center;
            margin-bottom: ${theme.spacing(5)};
          `}
        >
          Merci. Notre équipe reviendra vers vous rapidement.
        </Typography>
        <FontAwesomeIcon icon={faThumbsUp} size="4x" />
      </Root>
    </Layout>
  );
};

export default Merci;
